<template>
  <b-row>
    <b-col>
      <b-overlay
        :show="$apollo.loading"
        rounded="sm"
        spinner-variant="primary"
      >
        <b-card>
          <b-card-header
            class="justify-content-start p-0 pb-1 mb-1 border-bottom"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$router.back()"
            >
              <feather-icon
                class="mr-25"
                icon="ChevronLeftIcon"
              />
            </b-button>
            <h3 class="mb-0 ml-2">
              View Startups
            </h3>
          </b-card-header>
          <!-- search input -->
          <div class="custom-search d-flex justify-content-start">
            <b-form-group
              class="form-inline"
              label="Search"
              label-size="sm"
            >
              <b-form-input
                v-model="searchTerm"
                class="d-inline-block mr-1"
                placeholder="Search startups"
                type="text"
              />
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
            :columns="columns"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
            :rows="rows"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Action -->
              <span v-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    dropright
                    no-caret
                    size="sm"
                    toggle-class="text-decoration-none"
                    variant="link"
                  >
                    <template #button-content>
                      <feather-icon
                        class="text-body align-middle"
                        icon="MoreVerticalIcon"
                        size="16"
                      />
                    </template>
                    <b-dropdown-item
                      :to=" {name: 'progress-startups-reviews', params: { pid: $route.params.pid,
                                                                          aid: props.row.id,
                                                                          id: $route.params.id }} "
                    >
                      <feather-icon
                        class="mr-50"
                        icon="CopyIcon"
                      />
                      <span>View Reviews</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="selectedRow=props.row.originalIndex; $bvModal.show('recommendationModal')">
                      <feather-icon
                        class="mr-50"
                        icon="CopyIcon"
                      />
                      <span>Update Status</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :per-page="pageLength"
                    :total-rows="props.total"
                    :value="1"
                    align="right"
                    class="mt-1 mb-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-overlay>
      <b-modal
        v-if="rows[selectedRow]"
        id="recommendationModal"
        no-close-on-backdrop
        ok-only
        ok-title="Update"
        title="Recommendations"
        @ok="updateStatus"
      >
        <b-form-group
          label="Recommendations"
        >
          <b-form-input
            v-model="rows[selectedRow].programs_progressrecordtables[0].recommendations"
            placeholder="Your recommendation for startup"
          />
        </b-form-group>
        <b-form-group
          label="Status"
        >
          <v-select
            v-model="rows[selectedRow].programs_progressrecordtables[0].status"
            :options="['Excellent', 'Good', 'Satisfactory', 'Unsatisfactory', 'Incomplete']"
            placeholder="Select from list"
          />
        </b-form-group>
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>

import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BRow,
  BCardHeader,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardHeader,

    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  data() {
    return {
      searchTerm: '',
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          label: 'Startup',
          field(row) {
            return row.programs_applicantstable?.users_organizationtable?.title || '-'
          },
        },
        {
          label: 'Recommendations',
          field(row) {
            return row.programs_progressrecordtables[0]?.recommendations || '-'
          },
        },
        {
          label: 'Status',
          field(row) {
            return row.programs_progressrecordtables[0]?.status || '-'
          },
        },
      ],
      rows: [],
    }
  },
  methods: {
    updateStatus() {
      this.$apollo.mutate({
        mutation: gql`mutation MyMutation($object: programs_progressrecordtable_insert_input!) {
          insert_programs_progressrecordtable_one(object: $object, on_conflict: {constraint: programs_progressrecordtable_pkey, update_columns: [status, recommendations]}) {
            id
          }
        }`,
        variables: {
          object: this.rows[this.selectedRow].programs_progressrecordtables[0],
        },
        update: (store, { data: { insert_programs_progressrecordtable_one } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_programs_progressrecordtable_one.id ? 'Updated successfully' : 'Failed to update',
              icon: insert_programs_progressrecordtable_one.id ? 'CheckIcon' : 'XIcon',
              variant: insert_programs_progressrecordtable_one.id ? 'success' : 'warning',
            },
          })
          this.mutationLoading = false
        },

      })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_startupparticipants(where: {program_id: {_eq: ${this.$route.params.pid} }}) {
            id
            programs_applicantstable {
              users_organizationtable {
                title
              }
            }
            programs_progressrecordtables(where: {progress_stage_id: {_eq: ${this.$route.params.id}}}) {
              recommendations
              status
              id
              participant_id
              progress_stage_id
            }
          }
        }`
      },
      update(data) {
        data.programs_startupparticipants.forEach(e => {
          if (e.programs_progressrecordtables.length < 1) {
            e.programs_progressrecordtables.push({
              recommendations: '',
              status: '',
              participant_id: e.id,
              progress_stage_id: this.$route.params.id,
            })
          }
        })
        return data.programs_startupparticipants
      },
    },
  },
}
</script>
